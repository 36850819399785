<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			direction="rtl"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增附件</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
			
					<el-form-item prop="receiveUserId">
						<div class="inputtitle">
							<span>*</span>
							接受者：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.receiveUserId" filterable>
								<el-option
									v-for="item in employeesList"
									:key="item.userId"
									:value="item.userId"
									:label="item.realName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>
					
					<el-form-item prop="stageId">
						<div class="inputtitle">
							<span>*</span>
							项目阶段：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.stageId">
								<el-option
									v-for="item in allstage"
									:key="item.stageId"
									:value="item.stageId"
									:label="item.stageName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>

					<el-form-item label="" prop="docPath" style="margin-bottom: 0;">
						<div class="inputtitle" >
							<span>*</span>
							报价方案
						</div>
						<el-upload
							class="upload-demo"
							ref="upload"
							:action="$baseUrl + '/common/uploadFile'"
							:show-file-list="false"
							name="file"
							:on-success="baojiaUpFileSuccess"
							
						>
							<div slot="tip" class="el-upload__tip">上传文件类型：jpeg/jpg/png/mp4/xlsx/xls/doc/docx</div>
							<div class="upbox nochoosetext" style="">
								<i class="el-icon-plus"></i>
								<span>点击上传</span>
							</div>
						</el-upload>
						<!-- <template v-for="(item, index) in ruleForm.docList"> -->
							<div class="box1" v-if="ruleForm.docPath">
								<div class="box2">
									<img src="@/assets/customer/image5.png" />
									<span>{{ ruleForm.docName }}</span>
								</div>
								<img class="deleteicon" src="@/assets/customer/image4.png" @click="deleteDoc"/>
							</div>
						<!-- </template> -->
						
					</el-form-item>

				</el-form>

				<div class="twobtn">
					<div @click="resetForm">取消</div>
					<div class="save" @click="submitForm()">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from "vuex";
import { allUserList, stageListAll,uploadProjectDoc} from '@/network/api.js';
export default {
	data() {
		return {
			employeesList: [],
			allstage: '',   // 项目所有阶段
			drawer: false,
			ruleForm: {
				docName:'',    //  文档原始名称
				docPath:'',    //  文档路径
				docSize:'',    //  文档大小
				projectId:'',    //  项目id
				receiveUser:'',    //  接受者名称
				receiveUserId:'',    //  接受者id
				stageId:'',    //  项目阶段
				uploadUser:'',    //  上传者名称
				uploadUserId:'',    //  上传者id
			},
			rules: {
				docPath: [{ required: true, message: '请选择上传文件', trigger: 'blur' }],
				stageId: [{ required: true, message: '请选择项目阶段', trigger: 'blur' }],
				receiveUserId: [{ required: true, message: '请选择接收者', trigger: 'blur' }]
			}
		};
	},
	computed: {
		...mapState(['userInfo'])
	},
	created() {
		// console.log(this.userInfo);
		this._allUserList();
		this._stageListAll()
	},
	methods: {
		// 项目附件上传
		async _uploadProjectDoc() {
			this.employeesList.forEach(item => {
				if (item.userId == this.ruleForm.receiveUserId) {
					this.ruleForm.receiveUser = item.realName
				}
			})
			this.ruleForm.projectId = this.$route.query.id
			this.ruleForm.uploadUser = this.userInfo.realName
			this.ruleForm.uploadUserId = this.userInfo.userId
			console.log(this.ruleForm);
			let data = []
			for (let key in this.ruleForm) {
				let oneinfo = key + '=' + this.ruleForm[key]
				data.push(oneinfo)
			}
			let info = '?' + data.join('&')
			console.log(info);
			// return
			// await uploadProjectDoc(this.ruleForm)
			await uploadProjectDoc({data: info})
			this.$myMessage('添加成功','success')
			this.$emit('updataInfo')
			this.resetForm()
		},
		// 获取项目所有阶段
		async _stageListAll() {
			const res = await stageListAll({ projectId: this.$route.query.id });
			console.log('所有阶段', res);
			this.allstage = res.data;
			
		},
		// 获取所有员工列表
		async _allUserList() {
			const res = await allUserList();
			this.employeesList = res.data;
		},
		// 点击删除上传的文件
		deleteDoc() {
			this.ruleForm.docName = ''
			this.ruleForm.docPath = ''
			this.ruleForm.docSize = ''
		},
		// 上传报价文件成功回调 设计方案上传
		baojiaUpFileSuccess(info,file) {
			console.log(info,file);
			this.ruleForm.docName = file.name
			this.ruleForm.docSize = info.data.fileSize
			this.ruleForm.docPath = info.data.fileUrl
		},
		submitForm() {
			console.log(this.ruleForm);
			// return
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					this._uploadProjectDoc();
				} else {
					this.$myMessage('请将信息填写完整','success')
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
			this.ruleForm.docList = []
		}
	}
};
</script>

<style scoped lang="scss">
	::v-deep {
		.el-form-item__content {
			line-height: 200px;
		}
	}
.deleteicon {
	cursor: pointer;
	width: 16px;
	height: 16px;
}
.box2 {
	display: flex;
	align-items: center;
	img {
		width: 18px;
		height: 17px;
		margin: 0 12px 0 0;
	}
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
	}
}
.box1:hover {
	background-color: #faf9fb;
}
.box1 {
	margin: 0 0 15px 17px;
	width: 598px;
	height: 34px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0 20px 0 12px;
}
.upbox:active {
	opacity: 0.6;
}
.upbox {
	cursor: pointer;
	margin: 0 0 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 108px;
	height: 32px;
	background: rgba(46, 119, 246, 0.08);
	border-radius: 4px;
	border: 1px solid #2e77f6;
	i {
		color: #3286ff;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
		margin: 0 0 0 1em;
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}

::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}

.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
