<template>
	<div class="cluesdetail">
		<div class="box5">
			<div class="box6">
				<div class="box7">
					<div class="box7title">
						<div class="titletext">
							<div>1</div>
							<span>基础信息</span>
						</div>
						<div class="titlebtn nochoosetext" @click="showEdit">编辑</div>
					</div>
					<div class="box7content">
						<div class="onecontent">
							<span>项目名称：</span>
							<p>{{ projectinfo.projectName }}</p>
						</div>
						<div class="onecontent">
							<span>客户名称：</span>
							<p>{{ projectinfo.customerName }}</p>
						</div>
						<div class="onecontent">
							<span>公司类型：</span>
							<p>{{ projectinfo.customerTypeName }}</p>
						</div>
						<div class="onecontent">
							<span>所属行业：</span>
							<p>{{ projectinfo.companyIndustryName }}</p>
						</div>
						<div class="onecontent">
							<span>位置信息：</span>
							<p>{{ projectinfo.address }}</p>
						</div>
						<div class="onecontent">
							<span>公司简介：</span>
							<p>{{ projectinfo.customerDesc }}</p>
						</div>
						<div class="onecontent" style="align-items: flex-start;width: 80%;">
							<span>联系方式：</span>
							<div style="width: 80%;" class="allperson">
								<div
									v-for="item in projectinfo.contactVo"
									:key="item.contactId"
									style="max-width: 80%;"
								>
									<!-- 电话: {{item.contactPhone || '暂无'}} -->
									<span>姓名: {{ item.userName }}</span>
									<span>电话: {{ item.contactPhone || '暂无' }}</span>
									<span>职位: {{ item.positionName || '暂无' }}</span>
								</div>
							</div>
						</div>
						<div class="onecontent">
							<span>项目状态 ：</span>
							<p>{{ projectinfo.projectStageName }}</p>
						</div>
						<div class="onecontent">
							<span>负 责 人 ：</span>
							<p>{{ projectinfo.leaderName }}</p>
						</div>
						<div class="onecontent">
							<span>需求描述：</span>
							<p>{{ projectinfo.requirementDesc || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>项目人员：</span>
							<div class="allprojectperson">
								<p v-for="item in projectinfo.projectPerson" :key="item.userId">{{ item.realName }}</p>
							</div>
						</div>
						<div class="onecontent">
							<span>项目开始时间：</span>
							<p>{{ projectinfo.startTime || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>项目结束时间：</span>
							<p>{{ projectinfo.endTime || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>售后年限：</span>
							<p>{{ projectinfo.saleServiceYear || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>项目周期：</span>
							<p>{{ projectinfo.days || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>域 名：</span>
							<p>{{ projectinfo.domain || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>服务器到期：</span>
							<p>{{ projectinfo.serverExpire || '暂无' }}</p>
						</div>
						<div class="onecontent">
							<span>售后费用：</span>
							<p>{{ projectinfo.saleServicePrice || '暂无' }}元/年</p>
						</div>
						<div class="onecontent">
							<span>测试账号：</span>
							<div class="allzhanghao">
								<div
									class="onezhanghao"
									v-for="(item, index) in projectinfo.projectAccount"
									:key="index"
								>
									{{ item.accountName }}：{{ item.account }} 密码：{{ item.loginPassword }}
								</div>
								<!-- <div class="addbox">
									<div class="addbtn" @click="showAddZhanghao">
										<i class="el-icon-circle-plus-outline"></i>
										添加
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
				<div class="box7" style="margin-bottom: 0;">
					<div class="box7title">
						<div class="titletext">
							<div>2</div>
							<span>合伙人信息</span>
						</div>
						<div class="titlebtn nochoosetext" @click="showAddPartner">添加</div>
					</div>
					<div class="box7content">
						<div class="oneperson" v-for="(item, index) in projectinfo.projectPartner" :key="index">
							<div>
								<span>{{item.level == 1 ? '一' : item.level == 2 ? '二' : item.level == 3 ? '三' : item.level == 4 ? '四' : '五'}}
									级合伙人：</span>
								<p>{{ item.partnerName }}</p>
							</div>
							<div>
								<span>联系电话：</span>
								<p>{{ item.mobile }}</p>
							</div>
							<div>
								<span>项目分成：</span>
								<p>{{ item.incomePercent }}%</p>
							</div>
							<div v-if="isAdmin" class="titlebtn nochoosetext deletepar" @click="showDeletePartner(item.partnerId)">
								删除
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box8">
				<div class="box7title">
					<div class="titletext">
						<div>4</div>
						<span>跟进记录</span>
					</div>
					<div class="titlebtn nochoosetext" @click="showAddGen">新增</div>
				</div>
				<div class="box11">
					<div class="oneinfo" v-for="(item, index) in genjinList" :key="index">
						<div class="box11line"></div>
						<div class="time">
							<span>{{ item.year }}</span>
							<p>{{ item.hours }}</p>
						</div>
						<div class="point"></div>
						<div class="box10">
							<div class="box10title">
								<img src="@/assets/baseimg/image10.png" />
								<span>{{ item.realName }}</span>
							</div>
							<div class="box10content">
								<div class="contenttitle"><!-- 方案洽谈阶段 --></div>
								<div class="contenttext">{{ item.followContent }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tablebox1">
			<div class="box7title">
				<div class="titletext">
					<div>3</div>
					<span>相关附件</span>
				</div>
				<div class="titlebtn nochoosetext" @click="showAddDoc">新增</div>
			</div>
			<div class="tablecontent">
				<el-table :data="docList" border>
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="stageName" label="类别"></el-table-column>
					<el-table-column prop="docSize" label="大小"></el-table-column>
					<el-table-column prop="uploadUser" label="上传者">
					<!-- 	<template slot-scope="scope">
							<div class="textbox">{{ scope.row.address }}</div>
						</template> -->
					</el-table-column>
					<el-table-column prop="receiveUser" label="接收人">
				<!-- 		<template slot-scope="scope">
							<div class="textbox">{{ scope.row.customerDesc }}</div>
						</template> -->
					</el-table-column>
					<el-table-column prop="createdTime" label="时间"></el-table-column>
					<el-table-column prop="docName" label="附件名称"></el-table-column>

					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" @click="showEditDoc(scope.row)">
									<!-- <img src="@/assets/btnicon/image7.png" /> -->
									<i class="el-icon-upload"></i>
									传
								</div>

								<div class="kanbox gai" @click="clickDownLoad(scope.row.docPath)">
									<!-- <img src="@/assets/btnicon/image3.png" /> -->
									<i class="el-icon-download"></i>
									下
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<edit-info @updataInfo="_projectDetail" ref="editInfo"></edit-info>
		<add-zhanghao ref="addZhanghao"></add-zhanghao>
		<add-genjin @updataInfo="_projectFollowList" ref="addGenjin"></add-genjin>
		<add-partner @updataInfo="_projectDetail" ref="addPartner"></add-partner>
		<delete-popup message="确认要删除合伙人吗" @deleteInfo="_deleteProjectPartner" ref="deletepartner"></delete-popup>
		<add-doc @updataInfo="_projectDocList" ref="addDoc"></add-doc>
		<edit-doc @updataInfo="_projectDocList" ref="editDoc"></edit-doc>
	</div>
</template>

<script>
import {
	deleteCustomer,
	changeCustomerStatus,
	projectDetail,
	projectFollowList,
	deleteProjectPartner,
	projectDocList
} from '@/network/api.js';
import editInfo from './component/editInfo.vue';
import addZhanghao from './component/addZhanghao.vue';
import addGenjin from './component/addGenjin.vue';
import addPartner from './component/addPartner.vue';
import deletePopup from "@/components/common/deletePopup.vue"
import addDoc from "./component/addDoc.vue"
import editDoc from "./component/editDoc.vue"

import { mapState } from 'vuex';
export default {
	data() {
		return {
			docList: [],
			projectinfo: {},
			projectId: '',
			deletepartnerid: '',   // 删除合伙人id
			doclistInfo: {
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				projectId: '',
				searchBody: '',
				stageId: '',
			},
			genjinList: [] // 跟进列表
		};
	},
	name: 'customerDetail',
	computed: {
		// ...mapState(['customerStatus'])
		...mapState('systemModule', {
			customerStatusList: 'customerStatusList'
		}),
		permissionList() {
			return this.$store.state.permissionList;
		},
		isAdmin() {
			return this.$store.state.isadmin && localStorage.getItem('isStaff') == '0';
		}
	},
	components: {
		editInfo,
		addZhanghao,
		addGenjin,
		addPartner,
		deletePopup,
		addDoc,
		editDoc
	},
	created() {
		this.projectId = this.$route.query.id;
		this._projectDocList()
		this._projectDetail();
		this._projectFollowList();
	},
	methods: {
		// 点击出现修改文件弹框
		showEditDoc(info) {
			console.log(info);
			this.$refs.editDoc.drawer = true
			this.$refs.editDoc.ruleForm = {
				docId: info.docId,
				docName: info.docName,    //  文档原始名称
				docPath: info.docPath,    //  文档路径
				docSize: info.docSize,    //  文档大小
				projectId: info.projectId,    //  项目id
				receiveUser: info.receiveUser,    //  接受者名称
				receiveUserId: info.receiveUserId,    //  接受者id
				stageId: info.stageId,    //  项目阶段
				uploadUser: info.uploadUser,    //  上传者名称
				uploadUserId: info.uploadUserId,    //  上传者id
			}
		},
		// 点击下载
		clickDownLoad(url) {
			window.open(url)
		},
		// 点击出现添加附件弹框
		showAddDoc() {
			this.$refs.addDoc.drawer = true
		},
		// 获取项目附件列表
		async _projectDocList() {
			this.doclistInfo.projectId = this.projectId
			const res = await projectDocList(this.doclistInfo)
			console.log('附件列表',res);
			this.docList = res.data.list
		},
		// 删除合伙人
		async _deleteProjectPartner() {
			await deleteProjectPartner({
				data: `?partnerId=${this.deletepartnerid}&projectId=${this.projectId}`	
			})
			this.$myMessage('删除成功','success')
			this.$refs.deletepartner.dialogVisible = false
			this._projectDetail()
		},
		// 点击出现删除
		showDeletePartner(id) {
			this.deletepartnerid = id
			this.$refs.deletepartner.dialogVisible = true
		},
		
		// 显示添加合伙人
		showAddPartner() {
			this.$refs.addPartner.dialogVisible = true
			this.$refs.addPartner.addinfo.projectId = this.projectId
		},
		// 获取项目跟进记录
		async _projectFollowList() {
			const res = await projectFollowList({ projectId: this.projectId });
			console.log('跟进记录', res);
			if (res.data.length > 0) {
				res.data.forEach(item => {
					item.year = item.followTime.split(' ')[0];
					item.hours = item.followTime.split(' ')[1];
				});
			}
			this.genjinList = res.data || [];
		},
		// 获取项目详情
		async _projectDetail() {
			const res = await projectDetail({ projectId: this.projectId });
			console.log('项目详情', res);
			this.projectinfo = res.data;
		},
		// 点击出现添加账号弹框
		showAddZhanghao() {
			this.$refs.addZhanghao.dialogVisible = true;
		},
		// 点击编辑商机
		clickEditShangji(item) {
			// console.log(item);
			// return
			this.$refs.editShangji.drawer = true;
			this.$refs.editShangji.ruleForm = {
				chanceDesc: item.chanceDesc, //商机描述
				chanceTitle: item.chanceTitle, //商机标题
				customerId: this.customerId, //客户id
				docList: item.docList, //报价or设计方案文档
				chanceId: item.chanceId
			};
			console.log(this.$refs.editShangji.ruleForm);
		},

		// 点击删除
		clickDelete() {
			this.$refs.deleteclues.dialogVisible = true;
		},
		// 删除客户
		async _deleteCustomer() {
			const res = await deleteCustomer({
				customerId: this.customerId
			});
			this.$myMessage('删除成功', 'success');
			this.$refs.deleteclues.dialogVisible = false;
			this.$router.back();
		},

		// 点击跳转文档路径
		goDocUrl(item) {
			console.log(item);
			window.open(item.docPath);
		},


		// 添加跟进
		showAddGen() {
			this.$refs.addGenjin.dialogVisible = true;
			this.$refs.addGenjin.addinfo.projectId = this.projectId;
		},
		// 点击出现编辑弹框
		showEdit() {
			let personIds = this.projectinfo.projectPerson.map(item => item.userId)
			let info = JSON.parse(JSON.stringify(this.projectinfo));
			this.$refs.editInfo.ruleForm = {
				projectId: info.projectId,
				contractParams: info.contactVo, // 联系人
				customerId: info.customerId, // 客户id
				projectName: info.projectName, // 项目名称
				leaderId: info.leaderId, //项目负责人id
				leaderName: info.leaderName, // 项目负责人姓名
				personIds, // 项目人员
				startTime: info.startTime, // 项目开始时间
				endTime: info.endTime, // 项目结束时间
				domain: info.domain, // 服务器域名
				// projectStageId: '', //  项目阶段
				serverExpire: info.serverExpire || '', // 服务器到期时间
				saleServiceYear: info.saleServiceYear, // 售后年限
				projectAccount: info.projectAccount ,// 测试账号
				requirementDesc: info.requirementDesc,   // 需求描述
				saleServicePrice: info.saleServicePrice,  // 售后服务价格
			},
			this.$refs.editInfo.drawer = true
		},
	}
};
</script>

<style scoped lang="scss">
	.deletepar {
		width: 4em !important;
		height: 1.5em !important;
		font-size: 0.8125em !important;
	}
.allprojectperson {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	p {
		margin: 0 0.725em 0 0;
	}
}
.allperson {
	span {
		margin: 0 1.5em 0 0;
		padding: 0.25em;
		color: #3266f7 !important;
		font-size: 0.875em !important;
	}
}
.addbtn {
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #2e77f6;
	i {
		color: rgb(46, 119, 246);
		font-size: 1.25em;
		margin: 0 0.375em 0 0;
	}
}
.addbox {
	width: 100%;
}
.tablecontent {
	width: 100%;
	padding: 1.875em 0 1.25em 3em;
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 1em;
		height: 0.9375em;
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3dc57c;
		margin: 0 0 0 1.25em;
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
.tablebox1 {
	width: calc(100% - 2.5em);
	margin: 0 auto;
	// height: 10px;
	background-color: #ffffff;
}
.oneperson {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 0 1em 0;
	div {
		display: flex;
		align-items: center;
		margin-right: 3em;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #868e99;
	}
	p {
		color: #333333;
	}
}
.allzhanghao {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 5% 0 0;
}
.onezhanghao {
	// margin-right: 3.125em;
	margin: 0 0 0.5em 0;
	white-space: nowrap;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #444444;
}

::v-deep {
	.el-form-item__content {
		line-height: 22px;
	}
}
.onecontent1 {
	display: flex;
	align-items: center;
	// height: 2em;
	padding: 0.4em 0;
	p {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
	}
	span {
		color: #868e99;

		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
	}
}
::v-deep {
	.el-select__caret {
		color: #2d75f5 !important;
	}
	.el-input__inner {
		// border-color: #2d75f5 !important;
		width: 8.625em;
		height: 2.125em;
		line-height: 2.125em;
		// color: #2d75f5;
		font-size: 0.875em;
	}
	.el-input__icon {
		line-height: 2.125em;
	}
}
.box12 {
	display: flex;
	align-items: center;
	margin: 0.9375em 0 0 0;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #808080;
	}
	img {
		width: 0.75em;
		height: 1em;
		margin: 0 0.625em;
	}
	p {
		cursor: pointer;
		font-size: 0.875em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2e77f6;
	}
}
.box11line {
	right: calc(70% + 1.25em);
	position: absolute;
	width: 1px;
	height: 10.125em;
	background-color: #eeeeee;
}
.box11 {
	padding: 1.6875em 0 0 0;
	position: relative;
	width: 100%;
}
.contenttext {
	margin: 0.625em 0 0 0;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #808080;
}
.contenttitle {
	font-size: 1em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #666666;
}
.box10content {
	padding: 1em 1.25em;
}
.box10 {
	width: 70%;
	height: 8.875em;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #eeeeee;
}
.box10title {
	width: 100%;
	height: 3.0625em;
	padding: 0 1em;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eeeeee;
	img {
		width: 1.625em;
		height: 1.625em;
		border-radius: 50%;
		margin: 0 0.5625em 0 0;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #555555;
	}
}
.oneinfo {
	padding: 0 1.25em 0 0;
	display: flex;
	justify-content: flex-end;
	position: relative;
	margin: 0 0 1.25em 0;
}
.point {
	width: 0.5625em;
	height: 0.5625em;
	border-radius: 50%;
	background: #eeeeee;
	margin: 1.25em 0.625em 0;
}
.time {
	margin: 1em 0 0 0;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #808080;
	}
	p {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #666666;
	}
}
* {
	box-sizing: border-box;
}
.cluesdetail {
	width: 100%;
	min-width: 1000px;
}
.box8 {
	// background-color: red;
	position: absolute;
	width: calc(35% - 0.9375em - 8px);
	// width: 100%;
	height: calc(100% - 0.9375em);
	overflow-y: scroll;
	top: 0;
	right: 0.9375em;
	// left: 60%;

	background-color: #ffffff;
}
.oneshangji {
	width: 100%;
	background-color: #faf9fb;
	padding: 0.75em 1.6875em 0.9375em 0.9375em;
	margin: 0 0 0.9375em 0;
}
.shangjicontent {
	font-size: 0.875em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #808080;
	line-height: 1.45em;
}
.shangjititle {
	margin: 0 0 0.425em 0;
	font-size: 0.875em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;
}
.onecontent {
	display: flex;
	align-items: flex-start;
	padding: 0.4em 0;
	p {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
		max-width: 70%;
		white-space: pre-wrap;
		word-break: break-all;
	}
	span {
		white-space: nowrap;
		color: #868e99;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
	}
}
.box7content {
	width: 100%;
	background-color: #ffffff;
	padding: 0.9375em 3em;
}
.titlebtn:active {
	opacity: 0.6;
}
.titlebtn {
	cursor: pointer;
	width: 5em;
	height: 2em;
	background: rgba(50, 134, 255, 0.1);
	border-radius: 0.25em;
	border: 1px solid #4d91ff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #3286ff;
}
.titletext {
	display: flex;
	align-items: center;
	div {
		width: 1.125em;
		height: 1.125em;
		background: #0091ff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.75em;
		border-radius: 50%;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0.625em 0 0;
	}
	span {
		font-size: 1em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #444444;
	}
}
.box7title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 1.25em;
	height: 3.125em;
	border-bottom: 1px solid #eeeeee;
}
.box7 {
	width: 100%;
	background-color: #ffffff;
	margin: 0 0 0.9375em 0;
}
.box6 {
	width: 65%;
	display: flex;
	flex-direction: column;
}
.box5 {
	// display: flex;
	width: 100%;
	padding: 0 0.9375em 0.9375em;
	position: relative;
}
// .leftbtn {
// 	margin: 0 0 0 1.875em;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 8.625em;
// 	height: 2.125em;
// 	border-radius: 0.25em;
// 	border: 1px solid #2d75f5;
// 	font-size: 0.875em;
// 	font-family: PingFang-SC-Medium, PingFang-SC;
// 	font-weight: 500;
// 	color: #2e76f5;
// 	&.rightbtn {
// 		background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
// 		color: #ffffff;
// 	}
// }
// .leftbtn:active {
// 	opacity: 0.6;
// }
// .toptwobtn {
// 	cursor: pointer;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }
// .box4 {
// 	margin: 0 0 0 0.625em;
// 	font-size: 1.125em;
// 	font-family: PingFangSC-Medium, PingFang SC;
// 	font-weight: 500;
// 	color: #333333;
// }
// .box3 {
// 	font-size: 1.125em;
// 	font-family: PingFangSC-Regular, PingFang SC;
// 	font-weight: 400;
// 	color: #808080;
// }
// .box2 {
// 	margin: 0 0.625em 0 0;
// 	width: 0.1875em;
// 	height: 1.125em;
// 	background: linear-gradient(270deg, #0463ec 0%, #3286ff 100%);
// 	border-radius: 0.125em;
// }
// .box1 {
// 	display: flex;
// 	align-items: center;
// }
// .topde {
// 	height: 3.875em;
// 	padding: 0 1.25em;
// 	margin: 0.9375em auto;
// 	width: calc(100vw - 2.5em);
// 	display: flex;
// 	height: 2.5rem;
// 	background-color: #ffffff;
// 	align-items: center;
// 	justify-content: space-between;
// }
@media screen and (max-width: 1920px) {
	.cluesdetail {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.cluesdetail {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.cluesdetail {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.cluesdetail {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.cluesdetail {
		font-size: 12px;
	}
}
</style>
