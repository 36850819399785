<template>
	<div style="min-width: 1000px;">
		<div class="topde">
			<div class="box11">
				<div style="height: 100%;" v-for="(item, index) in toplist" :key="index">
					<div class="onetop" @click="chooseTop(index)">
						<span :class="{ activespan: index === topIndex }">{{ item.text }}</span>
						<div v-if="index === topIndex"></div>
					</div>
				</div>
			</div>
			<div class="toptwobtn">
				<div class="leftbtn">删除</div>
				<!-- <div class="leftbtn">编辑</div> -->
				<div class="leftbtn rightbtn" @click="showEditStatus">更改状态</div>
			</div>
		</div>
		<project-detail-info v-if="topIndex === 0" ref="projectDetailInfo"></project-detail-info>
		<project-phase v-if="topIndex === 1"></project-phase>
		<project-detail-task v-if="topIndex === 2"></project-detail-task>
		<edit-project-status @updataInfo="updataInfo" ref="editProjectStatus"></edit-project-status>
	</div>
</template>

<script>
	import projectDetailInfo from "./projectDetailInfo.vue"
	import projectPhase from "./projectPhase.vue"
	import projectDetailTask from "./projectDetailTask.vue"
	import editProjectStatus from "./component/editProjectStatus.vue"
	import { mapState } from "vuex"
	export default {
		data() {
			return {
				topIndex: 0,
				toplist: [
					{
						text: '基本信息',
						name: 'bumengManage'
					},
					{
						text: '项目阶段',
						name: 'jueseManage'
					},
					{
						text: '任务',
						name: 'zhanghaoManage'
					},
				],
			}
		},
		components: {
			projectDetailInfo,
			projectPhase,
			projectDetailTask,
			editProjectStatus
		},
		computed: {
			...mapState('systemModule',{
				customerStatusList: 'customerStatusList',
			}),
		},
		methods: {
			updataInfo(){
				if (this.$refs.projectDetailInfo) {
					this.$refs.projectDetailInfo._projectDetail()
				}
			},
			// 点击出现修改状态
			showEditStatus() {
				this.$refs.editProjectStatus.dialogVisible = true
			},
			setTitle() {
				document.title = this.toplist[this.topIndex].text
			},
			// 点击顶部导航
			chooseTop(index) {
				this.topIndex = index;
				this.setTitle()
			}
		},
	}
</script>

<style scoped lang="scss">
	.onetop {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 0 3.75em 0 0;
		cursor: pointer;
		span {
			font-size: 1.125em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #444444;
			&.activespan {
				font-size: 1.125em;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2d74f4;
			}
		}
		div {
			width: 1.875em;
			height: 0.1875em;
			position: absolute;
			bottom: 0;
			background-color: #3286ff;
		}
	}
	.box11 {
		display: flex;
		align-items: center;
		height: 100%;
	}
.leftbtn {
	margin: 0 0 0 1.875em;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 8.625em;
	height: 2.125em;
	border-radius: 0.25em;
	border: 1px solid #2d75f5;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #2e76f5;
	&.rightbtn {
		background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
		color: #ffffff;
	}
}
.leftbtn:active {
	opacity: 0.6;
}
.toptwobtn {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.box4 {
	margin: 0 0 0 0.625em;
	font-size: 1.125em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;
}
.box3 {
	font-size: 1.125em;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #808080;
}
.box2 {
	margin: 0 0.625em 0 0;
	width: 0.1875em;
	height: 1.125em;
	background: linear-gradient(270deg, #0463ec 0%, #3286ff 100%);
	border-radius: 0.125em;
}
.box1 {
	display: flex;
	align-items: center;
}
.topde {
	height: 3.875em;
	padding: 0 1.25em;
	margin: 0.9375em auto;
	width: calc(100% - 1.875em);
	min-width: 1000px;
	display: flex;
	height: 2.5rem;
	background-color: #ffffff;
	align-items: center;
	justify-content: space-between;
}
</style>