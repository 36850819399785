<template>
	<div>
		<el-drawer
			:visible.sync="drawer"
			direction="rtl"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增任务</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="taskTitle">
						<div class="inputtitle">
							<span>*</span>
							任务标题
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.taskTitle" placeholder="请输入商机标题"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="" prop="endTime">
						<div class="inputtitle">
							<span>*</span>
							截止时间
						</div>
						<div class="inputbox">
							<el-date-picker
							value-format="yyyy-MM-dd dd:HH:mm"
							      v-model="ruleForm.endTime"
							      type="datetime"
							      placeholder="选择日期时间">
							    </el-date-picker>
							<!-- <el-input v-model="ruleForm.chanceDesc" placeholder="请输入需求描述"></el-input> -->
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="assignUserId">
						<div class="inputtitle" style="margin-top: 0px;">
							<span>*</span>
							当前指派
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.assignUserId" filterable>
								<el-option
									v-for="item in employeesList"
									:key="item.userId"
									:value="item.userId"
									:label="item.realName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="level">
						<div class="inputtitle" style="margin-top: 0px;">
							<span>*</span>
							优先级
						</div>
						<el-select v-model="ruleForm.level">
							<el-option v-for="item in 5" :key="item"
							 :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="" prop="docParamsList" style="margin-bottom: 0;">
						<div class="inputtitle" >
							<span>*</span>
							报价方案
						</div>
						<el-upload
							class="upload-demo"
							ref="upload"
							:action="$baseUrl + '/common/uploadFile'"
							:show-file-list="false"
							name="file"
							:on-success="baojiaUpFileSuccess"
						>
					
							<div class="upbox nochoosetext" style="">
								<i class="el-icon-plus"></i>
								<span>点击上传</span>
							</div>
						</el-upload>
						<template v-for="(item, index) in ruleForm.docParamsList">
							<div class="box1" :key="index">
								<div class="box2">
									<img src="@/assets/customer/image5.png" />
									<span>{{ item.docName }}</span>
								</div>
								<img class="deleteicon" src="@/assets/customer/image4.png" @click="deleteDoc(index)"/>
							</div>
						</template>
						
					</el-form-item>

				
				</el-form>

				<div class="twobtn">
					<div @click="resetForm">取消</div>
					<div class="save" @click="submitForm()">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from "vuex"
import { allUserList,addProjectTask,projectTaskDetail } from '@/network/api.js';
export default {
	data() {
		return {
			employeesList: [], // 员工列表
			drawer: false,
			wayIndex: 0,
	
			ruleForm: {
				assignUserId: '', // 任务接受者id
				docParamsList: [],   // 项目任务相关附件
				endTime: '',   // 任务截止时间
				level: '',  // 优先级别
				projectId: '',  // 项目id
				taskContent: '',   // 任务描述
				taskTitle: '',   // 任务标题
			},
			rules: {
				level: [{ required: true, message: '请选择优先级', trigger: 'change' }],
				assignUserId: [{ required: true, message: '请选择接收者', trigger: 'change' }],
				endTime: [{ required: true, message: '请选择截止时间', trigger: 'change' }],
				taskTitle: [{ required: true, message: '请输入任务标题', trigger: 'blur' }],
				taskContent: [{ required: true, message: '请输入任务描述', trigger: 'blur' }],
				docParamsList: [{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }],
				
			}
		};
	},
	computed: {
		// ...mapState(['companyTypeList', 'cluesSourceList', 'companyIndustryList','customerType'])
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList',
			companyIndustryList: 'companyIndustryList',
			customerType: 'customerType',
		})
	},
	created() {
		this._allUserList()
		this.ruleForm.projectId = this.$route.query.id
	},
	methods: {
		// 获取任务详情
		async _projectTaskDetail(taskId) {
			const res = await projectTaskDetail({
				taskId
			})
			console.log('任务详情',res);
		},
		// 添加任务
		async _addProjectTask() {
			await addProjectTask(this.ruleForm)
			this.$myMessage('添加成功','success')
			this.resetForm();
			this.$emit('updataInfo');
		},
		// 获取所有员工列表
		async _allUserList() {
			const res = await allUserList();
			this.employeesList = res.data;
		},
		// 点击删除上传的文件
		deleteDoc(index) {
			this.ruleForm.docParamsList.splice(index,1)
		},
		// 上传报价文件成功回调 设计方案上传
		baojiaUpFileSuccess(info,file) {
			console.log(info,file);
			if (info.status !== 1) return this.$myMessage(info.message,"error")

			let fileinfo = {
				docName: file.name,  		//文档原始名称
				docPath: info.data.fileUrl,  		//文档路径
		
			}

			
			this.ruleForm.docParamsList.push(fileinfo)
	
		},



		submitForm() {
			console.log(this.ruleForm);
			// return
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
			
					this._addProjectTask();
				} else {
					this.$myMessage('请将信息填写完整','error')
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
			this.ruleForm.docList = []
		}
	}
};
</script>

<style scoped lang="scss">

	.oneway {
		cursor: pointer;
		margin: 0 30px 10px 0;
		display: flex;
		align-items: center;
		div {
			width: 14px;
			height: 14px;
			background: #ffffff;
			border: 1px solid #cccccc;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 10px 0 0;
			&.activeway {
				border: 1px solid #2e77f6;
			}
			p {
				width: 8px;
				background-color: #2e78f6;
				height: 8px;
				border-radius: 50%;
			}
		}
	}
	.allway {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0 0 0 17px;
	}
	::v-deep {
		.el-form-item__content {
			line-height: 20px;
		}
	}
.deleteicon {
	cursor: pointer;
	width: 16px;
	height: 16px;
}
.box2 {
	display: flex;
	align-items: center;
	img {
		width: 18px;
		height: 17px;
		margin: 0 12px 0 0;
	}
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
		
	}
}
.box1:hover {
	background-color: #faf9fb;
}
.box1 {
	margin: 0 0 15px 17px;
	width: 598px;
	height: 34px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0 20px 0 12px;
}
.upbox:active {
	opacity: 0.6;
}
.upbox {
	cursor: pointer;
	margin: 0 0 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 108px;
	height: 32px;
	background: rgba(46, 119, 246, 0.08);
	border-radius: 4px;
	border: 1px solid #2e77f6;
	i {
		color: #3286ff;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
		margin: 0 0 0 1em;
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}

::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}

.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
