<template>
	<div class="gonghai">


	
		<div class="tablebox">

			<div class="addbox" @click="showAddStage" v-if="permissionList.includes('myCluesManage:add') || isAdmin">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div class="tablebox11">
				<el-table :data="tableData" border>
					
					<el-table-column prop="stageName" label="项目阶段"></el-table-column>
					<el-table-column prop="principalName" label="负责人"></el-table-column>
					
					<el-table-column label="状态">
						<template slot-scope="scope">
							<div class="statusbox">
								<div class="status1" v-if="scope.row.stageStatus === 0">未开始</div>
								<div class="status2" v-if="scope.row.stageStatus === 1">进行中</div>
								<div class="status1" v-if="scope.row.stageStatus === 2">已结束</div>
								<div v-if="scope.row.stageStatus === 3">已逾期</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="realStartTime" label="实际开始"></el-table-column>
					<el-table-column prop="realEndTime" label="实际结束"></el-table-column>
					<el-table-column prop="planStartTime" label="预计开始"></el-table-column>
					<el-table-column prop="planEndTime" label="预计结束"></el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<div class="twobtn">
							<!-- 	<div class="kanbox">
									<img src="@/assets/btnicon/image7.png" >
									看
								</div> -->
								<div class="kanbox zeng" @click="showEditStage(scope.row)">
									<img src="@/assets/btnicon/image3.png">
									改
								</div>
								<div class="kanbox shan" @click="showDelete(scope.row.stageId)" v-if="isAdmin">
									<img src="@/assets/btnicon/image6.png" />
									删
								</div>
							<!-- 	<div class="kanbox gai" @click.stop="_customerInfo(scope.row.customerId)" v-if="permissionList.includes('myCluesManage:edit') || isAdmin">
									<img src="@/assets/btnicon/image3.png" >
									ok
								</div> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
				
				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<add-stage ref="addStage" @updataInfo="_stageList"></add-stage>
		<edit-stage ref="editStage"  @updataInfo="_stageList"></edit-stage>
		<delete-popup message="确认要删除阶段信息吗" ref="deleteStage" @deleteInfo="_deleteProjectStage"></delete-popup>
	</div>
</template>

<script>
	import  {customerList,customerInfo,stageList,deleteProjectStage} from "@/network/api.js"
	import { mapState } from "vuex"
	
	import addStage from "./component/addStage.vue"
	import editStage from "./component/editStage.vue"
	import deletePopup from "@/components/common/deletePopup.vue"

export default {
	data() {
		return {
			cluesIndex: 1,
			totalCount: 0,
			deleteId: '',
			searchInfo: {
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				projectId: '',
				searchBody: '',
				stageStatus: '',
			},
			tableData: []
		};
	},
	computed: {
		// ...mapState(['companyTypeList'])
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList'
		}),
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	components: {
		addStage,
		editStage,
		deletePopup
	},
	name: 'projectPhase',
	created() {
		this.searchInfo.projectId = this.$route.query.id;
		if (this.$route.query.startTime && this.$route.query.endTime) {
			this.searchInfo.startTime = this.$route.query.startTime
			this.searchInfo.endTime = this.$route.query.endTime
		}

		this._stageList()
	},
	methods: {
		// 删除阶段
		async _deleteProjectStage() {
			await deleteProjectStage({
				stageId: this.deleteId
			})
			this.$myMessage('删除成功','success')
			this.$refs.deleteStage.dialogVisible = false
			this._stageList()
		},
		// 点击出现删除弹框
		showDelete(id) {
			this.deleteId = id
			this.$refs.deleteStage.dialogVisible = true
		},
		// 点击出现编辑弹框
		showEditStage(info) {
			console.log(info);
			this.$refs.editStage.drawer = true
			this.$refs.editStage.ruleForm = {
				planEndTime: info.planEndTime, //计划结束时间
				planStartTime: info.planStartTime, // 计划开始时间
				principalId: info.principalId, // 负责人id
				principalName: info.principalName, // 负责人名称
				projectId: info.projectId, // 所属项目
				realEndTime: info.realEndTime, // 实际结束日期
				realStartTime: info.realStartTime, // 实际开始时间
				stageName: info.stageName, // 阶段名称
				stageId: info.stageId,
				stageStatus: info.stageStatus //阶段状态 0 未开始 1 进行中 2 已结束 3 已逾期
			}
		},
		// 点击出现添加弹框
		showAddStage() {
			this.$refs.addStage.drawer = true
			this.$refs.addStage.ruleForm.projectId = this.searchInfo.projectId
		},
		// 获取阶段列表
		async _stageList() {
			const res = await stageList(this.searchInfo)
			console.log('阶段列表',res);
			this.totalCount = res.data.totalCount
			this.tableData = res.data.list
		},
		handleSizeChange(val) {
			this.searchInfo.pageSize = val
			this._stageList()
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val
			this._stageList()
		}
	}
};
</script>

<style scoped lang="scss">
	.status1 {
		background: rgba(238, 238, 238, 0.7) !important;
		color: #808080 !important;
		border: 1px solid #AAAAAA !important;
	}
	.status2 {
		background: rgba(61, 197, 124, 0.1) !important;
		color: #3DC57C !important;
		border: 1px solid #3DC57C !important;
	}
	.tablebox {
		width: calc(100% - 2.5em);
		margin: 0 auto;
		background-color: #ffffff;
		padding: 1rem 1.25em;
	}
	.tablebox11 {
		margin: 1.25em 0 0;
	}
	.statusbox {
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			padding: 0 0.5em;
			height: 1.5em;
			background: rgba(242, 46, 24, 0.1);
			border-radius: 0.75em 0 0.75em 0.75em;
			border: 1px solid rgba(242, 46, 24, 0.6);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.875em;
			font-family: PingFang-SC-Regular, PingFang-SC;
			font-weight: 400;
			color: #F22E18;
		}
		
	}
	.textbox {
		 text-overflow: -o-ellipsis-lastline;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  line-clamp: 2;
		  -webkit-box-orient: vertical;
	}
	.box3 {
		width: 2.5em;
		height: 0.1875em;
		background: #3286FF;
		border-radius: 0.125em;
		position: absolute;
		
		bottom: 0;
	}
	.box2 {
		display: flex;
		align-items: flex-end;
		p {
			font-size: 1rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #666666;
			&.activep {
				color: #000000;
				font-size: 1.125em;
			}
		}
		span {
			font-size: 0.75em;
			&.activespan {
				color: #000000;
			}
		}
	}
	.box1 {
		cursor: pointer;
		margin: 0 1.25em 0 0;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.top1 {
		border: 1px solid #EEEEEE;
		width: 100%;
		height: 3.25em;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 1.0625em;
		
	}
	::v-deep {
		.pagebox {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin: 6.25em 0 0 1.25em;
			padding: 0 1.25em 0 0;
			.el-input__inner {
				color: #666666;
				width: 3em;
				height: 2.25em;
				line-height: 2.25em;
			}
			.el-select {
				.el-input__inner {
					color: #666666;
					width: 8em;
					height: 2.25em;
					line-height: 2.25em;
				}
			}
			
			.el-input__icon {
				line-height: 2.25em;
			}
		}
	}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 1em;
		height: 0.9375em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #FA7E33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3DC57C;
		margin: 0 0 0 1.25em;
	}
	&.zeng {
		background-color: #9F7EFF;
		margin: 0 0 0 1.25em;
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	// margin: 1.5625em 0 0 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	
	// border-radius: 0.25em;
	
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	
}
.tabletop {
	display: flex;
	align-items: center;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
.selectbox {
	margin: 0 0 0 2.5em;
	display: flex;
	align-items: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.6875em 0 0;
	}
}

.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

// .gonghai {
// 	padding: 0.9375em 0;
// }
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
