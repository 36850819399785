<template>
	<div>
		<el-drawer
			:visible.sync="drawer"
			direction="rtl"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>任务详情</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="">
				<div class="oneinfo">
					<span>任务描述：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>优 先 级：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>解决人员：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>当前指派：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>创建时间：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>截止时间：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>任务状态：</span>
					<p>任务处理进度不显示</p>
				</div>
				<div class="oneinfo">
					<span>截图上传：</span>
				</div>
				<img src="@/assets/login/image5.png" class="jietu">
			</div>
			
		
		</el-drawer>
	</div>
</template>

<script>

export default {
	data() {
		return {
			drawer: false,
		}
	},
	methods: {
		resetForm() {
			this.drawer = false
		}
	},

};
</script>

<style scoped lang="scss">
	.jietu {
		width: 200px;
		margin: 0 0 0 40px;
	}
	.oneinfo {
		padding: 0 0 22px 40px;
		display: flex;
		span {
			font-size: 14px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #666666;
		}
		p {
			color: #111111;
		}
	}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	margin: 0 0 30px 0;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
