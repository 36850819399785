<template>
	<div class="gonghai">
		<div class="tablebox">
			<div class="addbox" @click="showAddTask" v-if="permissionList.includes('myCluesManage:add') || isAdmin">
				<i class="el-icon-plus" style="color: #ffffff;"></i>
				<span>新增</span>
			</div>

			<div class="tablebox11">
				<el-table :data="tableData" border>
					
					<el-table-column prop="taskTitle" label="任务标题"></el-table-column>
					<el-table-column prop="assignUserName" label="负责人"></el-table-column>
					
					<el-table-column prop="companyTypeName" label="状态">
						<template slot-scope="scope">
							<div class="statusbox">
								<div v-if="scope.row.taskStatus === 0">待解决</div>
								<div class="isok" v-if="scope.row.taskStatus === 1">已解决</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column prop="createdTime" label="实际开始"></el-table-column>
					<el-table-column prop="endTime" label="实际结束"></el-table-column>
				
					<el-table-column label="操作" width="250">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" @click="showDetail">
									<img src="@/assets/btnicon/image7.png" >
									看
								</div>
								<div class="kanbox zeng" @click="showEdit(scope.row.taskId)">
									<img src="@/assets/btnicon/image3.png" >
									改
								</div>
								<div class="kanbox gai" v-if="permissionList.includes('myCluesManage:edit') || isAdmin">
									<img src="@/assets/btnicon/image3.png" >
									ok
								</div>
								<div class="kanbox shan" @click="showDelete(scope.row.taskId)" v-if="isAdmin">
									<img src="@/assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				
				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<add-task @updataInfo="_taskList" ref="addTask"></add-task>
		<task-detail ref="taskDetail"></task-detail>
		<edit-task ref="editTask"></edit-task>
		<delete-popup message="确认要删除任务吗" @deleteInfo="_deleteProjectTask" ref="deletePopup"></delete-popup>
	</div>
</template>

<script>
	import  {customerList,customerInfo,taskList,deleteProjectTask} from "@/network/api.js"
	import { mapState } from "vuex"
	
	
	import addTask from "./component/addTask.vue"
	import editTask from "./component/editTask.vue"
	import taskDetail from "./component/taskDetail.vue"
	import deletePopup from "@/components/common/deletePopup.vue"

export default {
	data() {
		return {
			deleteId: '',
			cluesIndex: 1,
			totalCount: 0,
			searchInfo: {
				assignUserId: '',  //项目接受人id
				projectId: '',
				taskId: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				searchBody: '',
			},
			tableData: []
		};
	},
	components: {
		addTask,
		taskDetail,
		editTask,
		deletePopup
	},
	computed: {
		// ...mapState(['companyTypeList'])
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList'
		}),
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	name: 'projectDetailTask',
	created() {

		this.searchInfo.projectId = this.$route.query.id
		this._taskList()
		
	},
	methods: {
		// 删除任务
		async _deleteProjectTask() {
			await deleteProjectTask({
				taskId: this.deleteId
			})
			this.$myMessage('删除成功','success')
			this.$refs.deletePopup.dialogVisible = false
			this._taskList()
		},
		// 点击出现删除弹框
		showDelete(id) {
			this.deleteId = id
			this.$refs.deletePopup.dialogVisible = true
		},
		// 点击出现编辑弹框
		showEdit(id) {
			this.$refs.editTask.drawer = true
			this.$refs.editTask._projectTaskDetail(id)
		},
		// 获取任务列表数据
		async _taskList() {
			const res = await taskList(this.searchInfo)
			this.tableData = res.data.list
			this.totalCount = res.data.totalCount
			console.log('任务列表',res);
		},
		// 点击出现详情弹框
		showDetail() {
			this.$refs.taskDetail.drawer = true
		},
		// 点击出现新增任务弹框
		showAddTask() {
			this.$refs.addTask.drawer = true
		},

		handleSizeChange(val) {
			
			this.searchInfo.pageSize = val
			this._customerList()
		},
		handleCurrentChange(val) {
			
			this.searchInfo.page = val
			this._customerList()
		}
	}
};
</script>

<style scoped lang="scss">
	.tablebox {
		width: calc(100% - 2.5em);
		margin: 0 auto;
		background-color: #ffffff;
		padding: 1rem 1.25em;
	}
	.tablebox11 {
		margin: 1.25em 0 0;
	}
	.statusbox {
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			width: 3.75em;
			height: 1.5em;
			background: rgba(242, 46, 24, 0.1);
			border-radius: 0.75em 0 0.75em 0.75em;
			border: 1px solid rgba(242, 46, 24, 0.6);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.875em;
			font-family: PingFang-SC-Regular, PingFang-SC;
			font-weight: 400;
			color: #F22E18;
			&.isok {
				color: #3DC57C;
				background: rgba(61, 197, 124, 0.1);
				border: 1px solid #3DC57C;
			}
		}
	}
	.textbox {
		 text-overflow: -o-ellipsis-lastline;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  line-clamp: 2;
		  -webkit-box-orient: vertical;
	}
	.box3 {
		width: 2.5em;
		height: 0.1875em;
		background: #3286FF;
		border-radius: 0.125em;
		position: absolute;
		
		bottom: 0;
	}
	.box2 {
		display: flex;
		align-items: flex-end;
		p {
			font-size: 1rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #666666;
			&.activep {
				color: #000000;
				font-size: 1.125em;
			}
		}
		span {
			font-size: 0.75em;
			&.activespan {
				color: #000000;
			}
		}
	}
	.box1 {
		cursor: pointer;
		margin: 0 1.25em 0 0;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.top1 {
		border: 1px solid #EEEEEE;
		width: 100%;
		height: 3.25em;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 1.0625em;
		
	}
	::v-deep {
		.pagebox {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin: 6.25em 0 0 1.25em;
			padding: 0 1.25em 0 0;
			.el-input__inner {
				color: #666666;
				width: 3em;
				height: 2.25em;
				line-height: 2.25em;
			}
			.el-select {
				.el-input__inner {
					color: #666666;
					width: 8em;
					height: 2.25em;
					line-height: 2.25em;
				}
			}
			
			.el-input__icon {
				line-height: 2.25em;
			}
		}
	}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 1em;
		height: 0.9375em;
	}
	&.gen {
		background-color: #FA7E33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gai {
		background-color: #3DC57C;
		margin: 0 0 0 1.25em;
	}
	&.zeng {
		background-color: #9F7EFF;
		margin: 0 0 0 1.25em;
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	// margin: 1.5625em 0 0 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chongzhi {
	width: 5.625em;
	height: 2.25em;
	font-size: 0.875em;
}
.chaxun {
	margin: 0 1.25em 0 2.5em;
	width: 5.625em;
	height: 2.25em;
	
	// border-radius: 0.25em;
	
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	
}
.tabletop {
	display: flex;
	align-items: center;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
.selectbox {
	margin: 0 0 0 2.5em;
	display: flex;
	align-items: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.6875em 0 0;
	}
}

.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
