<template>
	<div class="addtan">
		<el-dialog
			:show-close="false"
			width="600px"
			:visible.sync="dialogVisible"
			:before-close="handleClose"
		>
			<div class="tanbox">
				<div class="titlebox">
					<span>新增跟进</span>
					<i @click="handleClose" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
				</div>
				<div class="formbox">
					<div class="inputtitle">
						<span>*</span>
						跟进时间
					</div>
					<el-date-picker
						value-format="yyyy-MM-dd HH:mm:ss"
						v-model="addinfo.followTime"
						type="datetime"
						placeholder="选择日期时间"
					></el-date-picker>
				
					<div class="inputtitle" style="margin-top: 25px;">
						<span>*</span>
						跟进内容
					</div>
					<textarea v-model="addinfo.followContent"></textarea>

					<div class="twobtn">
						<div @click="handleClose">取消</div>
						<div class="save" @click="_addProjectFollow">保存</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { addProjectFollow } from "@/network/api.js"
	import { mapState } from "vuex"
export default {
	data() {
		return {
			dialogVisible: false,
			value1: '',
			wayIndex: 0,
			addinfo: {
				followContent: '',			//跟进内容
				followTime: '',			//跟进时间
				projectId: '',			//项目id
			}
		};
	},
	computed: {
		
		...mapState('systemModule',{
			genjinType: 'genjinType'
		})
	},
	methods: {
		// 添加项目跟进跟进
		async _addProjectFollow() {
			if (!this.addinfo.followContent) return this.$myMessage('请输入跟进内容','error')
			if (!this.addinfo.followTime) return this.$myMessage('请选择跟进时间','error')
			const res = await addProjectFollow(this.addinfo)
			this.$myMessage('添加成功','success')
			this.handleClose()
			this.$emit('updataInfo')
		},
		// 选择跟进方式
		chooseWay(item) {
			this.addinfo.followTypeId = item.settingId
		},
		handleClose() {
			this.dialogVisible = false
			this.addinfo = {
				followContent: '',			//跟进内容
				followTime: '',			//跟进时间
				projectId: '',			//项目id
			}
		}
	}
};
</script>

<style scoped lang="scss">
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
textarea {
	font-size: 14px;
	outline: none;
	padding: 13px 20px;
	width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
@media screen and (max-width: 1920px) {
	.addtan {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.addtan {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.addtan {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.addtan {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.addtan {
		font-size: 12px;
	}
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-dialog__header {
		padding: 0;
	}
	.el-dialog__body {
		padding: 0;
	}
	.el-dialog {
		border-radius: 10px;
	}
}
</style>
