<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			direction="rtl"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增阶段</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="stageName">
						<div class="inputtitle" style="margin-top: 0px;">
							<span>*</span>
							阶段名称：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.stageName">
								<el-option
									v-for="item in projectStatus"
									:key="item"
									:value="item"
									:label="item"
								></el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item prop="principalId">
						<div class="inputtitle">
							<span>*</span>
							阶段负责人：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.principalId" filterable>
								<el-option
									v-for="item in employeesList"
									:key="item.userId"
									:value="item.userId"
									:label="item.realName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>

					<el-form-item label="" prop="stageStatus">
						<div class="inputtitle">
							<span>*</span>
							阶段状态：
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.stageStatus">
								<el-option :value="0" label="未开始"></el-option>
								<el-option :value="1" label="进行中"></el-option>
								<el-option :value="2" label="已结束"></el-option>
								<el-option :value="3" label="已逾期"></el-option>
							</el-select>
						</div>
					</el-form-item>

					<el-form-item label="" prop="planStartTime">
						<div class="inputtitle">
							<span>*</span>
							预计开始时间：
						</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd"
								v-model="ruleForm.planStartTime"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="" prop="planEndTime">
						<div class="inputtitle">
							<span>*</span>
							预计结束时间：
						</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd"
								v-model="ruleForm.planEndTime"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="" prop="realStartTime">
						<div class="inputtitle">
							
							实际开始时间：
						</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd"
								v-model="ruleForm.realStartTime"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item label="" prop="realEndTime">
						<div class="inputtitle">
							
							实际结束时间：
						</div>
						<div class="inputbox">
							<el-date-picker
								value-format="yyyy-MM-dd"
								v-model="ruleForm.realEndTime"
								type="date"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
				</el-form>
				<div class="twobtn">
					<div @click="resetForm()">取消</div>
					<div class="save" @click="submitForm()">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {
	editProjectStage,
	allUserList,
	stageListAll,
} from '@/network/api.js';

export default {
	data() {
		return {
			employeesList: [], // 员工列表
			ruleForm: {
				planEndTime: '', //计划结束时间
				planStartTime: '', // 计划开始时间
				principalId: '', // 负责人id
				principalName: '', // 负责人名称
				projectId: '', // 所属项目
				realEndTime: '', // 实际结束日期
				realStartTime: '', // 实际开始时间
				stageName: '', // 阶段名称
				stageId: '',
				stageStatus: '' //阶段状态 0 未开始 1 进行中 2 已结束 3 已逾期
			},
			rules: {
				planEndTime: [{ required: true, message: '请选择预计结束时间', trigger: 'blur' }],
				planStartTime: [{ required: true, message: '请选择预计开始时间', trigger: 'blur' }],
				stageStatus: [{ required: true, message: '请选择阶段状态', trigger: 'blur' }],
				stageName: [{ required: true, message: '请选择阶段名称', trigger: 'blur' }],
				principalId: [{ required: true, message: '请选择阶段负责人', trigger: 'blur' }],
				
			},
			drawer: false
		};
	},
	computed: {
		...mapState(['projectStatus']),
	},
	created() {
		this._allUserList();
	},
	methods: {
		// 编辑阶段
		async _editProjectStage() {
			// 获取负责人姓名
			this.employeesList.forEach(item => {
				if (item.userId == this.ruleForm.principalId) {
					this.ruleForm.principalName = item.realName;
				}
			});
			let info = JSON.parse(JSON.stringify(this.ruleForm))
			// 处理时间格式
			// info.planEndTime = info.planEndTime + ' 00:00:00'
			// info.planStartTime = info.planStartTime + ' 00:00:00'
			// if (info.realEndTime) {
			// 	info.realEndTime = info.realEndTime + ' 00:00:00'
			// }
			// if (info.realStartTime) {
			// 	info.realStartTime = info.realStartTime + ' 00:00:00'
			// }
			const loading = this.$loading({
				// 登录是的loading
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			let settime = setTimeout(function() {
				loading.close();
			}, 3000);
			const res = await editProjectStage(info);
			clearTimeout(settime);
			loading.close();
			this.$myMessage('编辑成功', 'success');
			this.resetForm();
			this.$emit('updataInfo');
		},
	
		// 获取所有员工列表
		async _allUserList() {
			const res = await allUserList();
			this.employeesList = res.data;
		},

		submitForm() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					this._editProjectStage();
				} else {
					this.$myMessage('请将信息填写完整', 'error');
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
		}
	}
};
</script>

<style scoped lang="scss">
.yeartext {
	margin: 0 0 0 20px;
}
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
}
.deleteicon {
	cursor: pointer;
	width: 16px;
	height: 16px;
}
.box2 {
	display: flex;
	align-items: center;
	img {
		width: 18px;
		height: 17px;
		margin: 0 12px 0 0;
	}
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
	}
}
.box1:hover {
	background-color: #faf9fb;
}
.box1 {
	margin: 0 0 15px 17px;
	width: 598px;
	height: 34px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding: 0 20px 0 12px;
}
.upbox:active {
	opacity: 0.6;
}
.upbox {
	cursor: pointer;
	margin: 0 0 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 108px;
	height: 32px;
	background: rgba(46, 119, 246, 0.08);
	border-radius: 4px;
	border: 1px solid #2e77f6;
	i {
		color: #3286ff;
	}
	span {
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3286ff;
		margin: 0 0 0 1em;
	}
}
::v-deep {
	.inputbox1 {
		.el-input__inner {
			width: 180px !important;
			height: 40px !important;
		}
		// span {
		// 	margin: 0 0 0 20px;
		// }
	}
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
.addinputbox {
	display: flex;
	align-items: center;
	margin: 15px 0 0 0;
	padding: 0 0 0 17px;
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
	}
	input {
		width: 400px !important;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #eeeeee;
	}
}
.onerelation {
	padding: 0 0 0 17px;
	display: flex;
	align-items: center;
	margin: 0 0 20px 0;
	span {
		font-size: 14px;
		color: #aeaeae;
		margin: 0 10px 0 0;
	}
	i {
		cursor: pointer;
	}
	p {
		margin: 0 20px 0 0;
		font-size: 14px;
		color: #333333;
	}
}
.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 25px 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
textarea {
	font-size: 14px;
	outline: none;
	padding: 13px 20px;
	width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
